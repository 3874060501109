import Products from "../../Components/Products";

function Category() {
  return (
    <div>
      <Products />
    </div>
  );
}
export default Category;
